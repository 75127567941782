<div class="d-flex flex-row ds-value-row" *ngVar="mdValue.newValue.isVirtual as isVirtual" role="row"
     cdkDrag (cdkDragStarted)="dragging.emit(true)" (cdkDragEnded)="dragging.emit(false)"
     [ngClass]="{ 'ds-warning': mdValue.reordered || mdValue.change === DsoEditMetadataChangeTypeEnum.UPDATE, 'ds-danger': mdValue.change === DsoEditMetadataChangeTypeEnum.REMOVE, 'ds-success': mdValue.change === DsoEditMetadataChangeTypeEnum.ADD, 'h-100': isOnlyValue }">
  <div class="flex-grow-1 ds-flex-cell ds-value-cell d-flex align-items-center" *ngVar="(mdRepresentation$ | async) as mdRepresentation" role="cell">
    <div class="dont-break-out preserve-line-breaks" *ngIf="!mdValue.editing && !mdRepresentation">{{ mdValue.newValue.value }}</div>
    <textarea class="form-control" rows="5" *ngIf="mdValue.editing && !mdRepresentation" [(ngModel)]="mdValue.newValue.value"
              [attr.aria-label]="(dsoType + '.edit.metadata.edit.value') | translate"
              [dsDebounce]="300" (onDebounce)="confirm.emit(false)"></textarea>
    <div class="d-flex" *ngIf="mdRepresentation">
      <a class="mr-2" target="_blank" [routerLink]="mdRepresentationItemRoute$ | async">{{ mdRepresentationName$ | async }}</a>
      <ds-themed-type-badge [object]="mdRepresentation"></ds-themed-type-badge>
    </div>
  </div>
  <div class="ds-flex-cell ds-lang-cell" role="cell">
    <div class="dont-break-out preserve-line-breaks" *ngIf="!mdValue.editing">{{ mdValue.newValue.language }}</div>
    <input class="form-control" type="text" *ngIf="mdValue.editing" [(ngModel)]="mdValue.newValue.language"
           [attr.aria-label]="(dsoType + '.edit.metadata.edit.language') | translate"
           [dsDebounce]="300" (onDebounce)="confirm.emit(false)" />
  </div>
  <div class="text-center ds-flex-cell ds-edit-cell" role="cell">
    <div class="btn-group">
      <div class="edit-field">
        <div class="btn-group edit-buttons" [ngbTooltip]="isVirtual ? (dsoType + '.edit.metadata.edit.buttons.virtual' | translate) : null">
          <button class="btn btn-outline-primary btn-sm ng-star-inserted" data-test="metadata-edit-btn" *ngIf="!mdValue.editing"
                  [title]="dsoType + '.edit.metadata.edit.buttons.edit' | translate"
                  ngbTooltip="{{ dsoType + '.edit.metadata.edit.buttons.edit' | translate }}"
                  [disabled]="isVirtual || mdValue.change === DsoEditMetadataChangeTypeEnum.REMOVE || (saving$ | async)" (click)="edit.emit()">
            <i class="fas fa-edit fa-fw"></i>
          </button>
          <button class="btn btn-outline-success btn-sm ng-star-inserted" data-test="metadata-confirm-btn" *ngIf="mdValue.editing"
                  [title]="dsoType + '.edit.metadata.edit.buttons.confirm' | translate"
                  ngbTooltip="{{ dsoType + '.edit.metadata.edit.buttons.confirm' | translate }}"
                  [disabled]="isVirtual || (saving$ | async)" (click)="confirm.emit(true)">
            <i class="fas fa-check fa-fw"></i>
          </button>
          <button class="btn btn-outline-danger btn-sm" data-test="metadata-remove-btn"
                  [title]="dsoType + '.edit.metadata.edit.buttons.remove' | translate"
                  ngbTooltip="{{ dsoType + '.edit.metadata.edit.buttons.remove' | translate }}"
                  [disabled]="isVirtual || (mdValue.change && mdValue.change !== DsoEditMetadataChangeTypeEnum.ADD) || mdValue.editing || (saving$ | async)" (click)="remove.emit()">
            <i class="fas fa-trash-alt fa-fw"></i>
          </button>
          <button class="btn btn-outline-warning btn-sm" data-test="metadata-undo-btn"
                  [title]="dsoType + '.edit.metadata.edit.buttons.undo' | translate"
                  ngbTooltip="{{ dsoType + '.edit.metadata.edit.buttons.undo' | translate }}"
                  [disabled]="isVirtual || (!mdValue.change && mdValue.reordered) || (!mdValue.change && !mdValue.editing) || (saving$ | async)" (click)="undo.emit()">
            <i class="fas fa-undo-alt fa-fw"></i>
          </button>
        </div>
      </div>
      <button class="btn btn-outline-secondary ds-drag-handle btn-sm" data-test="metadata-drag-btn" *ngVar="(isOnlyValue || (saving$ | async)) as disabled"
              cdkDragHandle [cdkDragHandleDisabled]="disabled" [ngClass]="{'disabled': disabled}" [disabled]="disabled"
              [title]="dsoType + '.edit.metadata.edit.buttons.drag' | translate"
              ngbTooltip="{{ dsoType + '.edit.metadata.edit.buttons.drag' | translate }}">
        <i class="fas fa-grip-vertical fa-fw"></i>
      </button>
    </div>
  </div>
</div>
