<div class="social-kit-container d-flex flex-row justify-content-center gap-3">
  <ng-container *ngFor="let icon of icons">
  <a *ngIf="icon.type !== 'link'" class="social-kit-link" [ngbTooltip]="icon.tooltip"
     target="_blank" [href]="icon.shareLink">
    <i [class]="icon.iconClass + ' social-kit-icon'"></i>
  </a>
  <a *ngIf="icon.type === 'link'" class="social-kit-link" [ngbTooltip]="icon.tooltip"
      [cdkCopyToClipboard]="icon.shareLink">
    <i [class]="icon.iconClass + ' social-kit-icon'"></i>
  </a>
  </ng-container>
</div>
