<ng-container *ngIf="shouldDisplayBothRelationshipSides$ | async">
    <ds-edit-relationship-list
            [url]="url"
            [item]="item"
            [itemType]="itemType"
            [relationshipType]="relationshipType"
            [hasChanges]="hasChanges"
            [currentItemIsLeftItem$]="isLeftItem$"
            class="d-block mb-4"
    ></ds-edit-relationship-list>
    <ds-edit-relationship-list
            [url]="url"
            [item]="item"
            [itemType]="itemType"
            [relationshipType]="relationshipType"
            [hasChanges]="hasChanges"
            [currentItemIsLeftItem$]="isRightItem$"
    ></ds-edit-relationship-list>
</ng-container>

<ng-container *ngIf="(shouldDisplayBothRelationshipSides$ | async) === false">
    <ds-edit-relationship-list
            [url]="url"
            [item]="item"
            [itemType]="itemType"
            [relationshipType]="relationshipType"
            [hasChanges]="hasChanges"
            [currentItemIsLeftItem$]="currentItemIsLeftItem$"
    ></ds-edit-relationship-list>
</ng-container>

