<div class="item-page-field" *ngIf="item?.firstMetadataValue('cg.identifier.doi')">
  <div class="simple-view-element">
    <div class="simple-view-element-body">
      <ng-container [ngTemplateOutlet]="dimensionsBadge"
                    [ngTemplateOutletContext]="{
                                                itemDoi: item?.firstMetadataValue('cg.identifier.doi'),
                                              }">
      </ng-container>
    </div>
  </div>
</div>

<!-- See: https://badges.dimensions.ai -->
<ng-template #dimensionsBadge let-itemDoi="itemDoi">
  <span class="__dimensions_badge_embed__" [attr.data-doi]=parseDoi(itemDoi) data-hide-zero-citations="true"
        data-style="small_circle" [attr.data-legend]="legend"></span>
</ng-template>
