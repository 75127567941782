<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>{{headerMessage | translate: {id: item.handle} }}</h1>
            <p>{{descriptionMessage | translate}}</p>
            <ds-modify-item-overview [item]="item"></ds-modify-item-overview>
          <div class="space-children-mr">
            <button (click)="performAction()" class="btn btn-outline-secondary perform-action">{{confirmMessage | translate}}
            </button>
            <button [routerLink]="[itemPageRoute, 'edit']" class="btn btn-outline-secondary cancel">
              {{cancelMessage| translate}}
            </button>
          </div>
        </div>
    </div>

</div>
