<div>
  <ds-form *ngIf="formModel"
           #formRef="formComponent"
           [formId]="formId"
           [formModel]="formModel"
           [displaySubmit]="false"
           [displayCancel]="false"></ds-form>
  <div class="container-fluid">
    <label for="ResourcePolicyObject">{{'resource-policies.form.eperson-group-list.label' | translate}}</label>
    <input id="ResourcePolicyObject" class="form-control mb-3" type="text" [value]="resourcePolicyTargetName$ | async">
    <ul ngbNav #nav="ngbNav" class="nav-pills" [(activeId)]="navActiveId" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="'eperson'" role="presentation">
        <a ngbNavLink>{{'resource-policies.form.eperson-group-list.tab.eperson' | translate}}</a>
        <ng-template ngbNavContent>
          <ds-eperson-group-list (select)="updateObjectSelected($event, true)"></ds-eperson-group-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="'group'" role="presentation">
        <a ngbNavLink>{{'resource-policies.form.eperson-group-list.tab.group' | translate}}</a>
        <ng-template ngbNavContent>
          <ds-eperson-group-list [isListOfEPerson]="false"
                                 (select)="updateObjectSelected($event, false)"></ds-eperson-group-list>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
    <div>
      <hr>
      <div class="form-group row">

        <div class="col text-right">
          <button type="reset"
                  class="btn btn-default"
                  [disabled]="(isProcessing | async)"
                  (click)="onReset()">{{'form.cancel' | translate}}</button>
          <button type="button"
                  class="btn btn-primary"
                  [disabled]="!(isFormValid() | async) || (isProcessing | async)"
                  (click)="onSubmit()">
            <span *ngIf="(isProcessing | async)">
                  <i class='fas fa-circle-notch fa-spin'></i> {{'submission.workflow.tasks.generic.processing' | translate}}
                </span>
            <span *ngIf="!(isProcessing | async)">
                  {{'form.submit' | translate}}
                </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'resource-policies.form.eperson-group-list.modal.header' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-row">
      <div class="mr-3">
        <i class="fas fa-info-circle fa-2x text-info"></i>
      </div>
      <div>
      <p [innerHTML]="(navActiveId === 'eperson' ? 'resource-policies.form.eperson-group-list.modal.text1.toGroup' :
       'resource-policies.form.eperson-group-list.modal.text1.toEPerson') | translate" class="font-weight-bold"></p>
      <p [innerHTML]="'resource-policies.form.eperson-group-list.modal.text2' | translate"></p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">{{ 'resource-policies.form.eperson-group-list.modal.close' | translate }}</button>
  </div>
</ng-template>
