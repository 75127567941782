<div class="container">
    <div class="row">
        <div class="col-12">

            <h1>{{headerMessage | translate: {id: item.handle} }}</h1>
            <p>{{descriptionMessage | translate}}</p>
            <ds-modify-item-overview [item]="item"></ds-modify-item-overview>

            <ng-container *ngVar="(typeDTOs$ | async) as types">

                <div *ngIf="types && types.length > 0" class="mb-4">

                    {{'virtual-metadata.delete-item.info' | translate}}

                    <div *ngFor="let typeDto of types" class="mb-4">
                        <div *ngVar="(typeDto.isSelected$ | async) as selected"
                             class="d-flex flex-row">

                            <div class="m-2" (click)="setSelected(typeDto.relationshipType, !selected)">
                                <label>
                                    <input type="checkbox" [checked]="selected" [disabled]="isDeleting$ | async">
                                </label>
                            </div>

                            <div class="flex-column flex-grow-1">
                                <h5 (click)="setSelected(typeDto.relationshipType, !selected)">
                                    {{getRelationshipMessageKey(typeDto.label$ | async) | translate}}
                                </h5>
                                <div *ngFor="let relationshipDto of (typeDto.relationshipDTOs$ | async)"
                                     class="d-flex flex-row">
                                    <ng-container *ngVar="(relationshipDto.relatedItem$ | async) as relatedItem">

                                        <ds-listable-object-component-loader
                                                *ngIf="relatedItem"
                                                [object]="relatedItem"
                                                [viewMode]="viewMode">
                                        </ds-listable-object-component-loader>
                                        <div class="ml-auto">
                                            <div class="btn-group">
                                                <button class="btn btn-outline-info btn-sm"
                                                        (click)="openVirtualMetadataModal(virtualMetadataModal)">
                                                    <i class="fas fa-info fa-fw"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <ng-template #virtualMetadataModal>
                                            <div class="thumb-font-1">
                                                <div class="modal-header">
                                                    {{'virtual-metadata.delete-item.modal-head' | translate}}
                                                    <button type="button" class="close"
                                                            (click)="closeVirtualMetadataModal()" aria-label="Close">
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <ds-listable-object-component-loader
                                                            *ngIf="relatedItem"
                                                            [object]="relatedItem"
                                                            [viewMode]="viewMode">
                                                    </ds-listable-object-component-loader>
                                                    <div *ngFor="let metadata of (relationshipDto.virtualMetadata$ | async)">
                                                        <div>
                                                            <div class="font-weight-bold">
                                                                {{metadata.metadataField}}
                                                            </div>
                                                            <div>
                                                                {{metadata.metadataValue.value}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>

                                    </ng-container>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </ng-container>

            <div class="space-children-mr">
              <button [disabled]="isDeleting$ | async" (click)="performAction()"
                      class="btn btn-outline-secondary perform-action">{{confirmMessage | translate}}
              </button>
              <button [disabled]="isDeleting$ | async" [routerLink]="[itemPageRoute, 'edit']"
                      class="btn btn-outline-secondary cancel">
                {{cancelMessage| translate}}
              </button>
            </div>

        </div>
    </div>
</div>
