<div class="item-page-field">
  <div class="simple-view-element">
    <div class="simple-view-element-body">
      <ng-container [ngTemplateOutlet]="altmetricBadgeDoi"
                    [ngTemplateOutletContext]="{
                                                itemDoi: item?.firstMetadataValue('cg.identifier.doi'),
                                              }"
                    *ngIf="item?.firstMetadataValue('cg.identifier.doi')">
      </ng-container>
      <!-- This should be safe because all DSpace items have a handle -->
      <ng-container [ngTemplateOutlet]="altmetricBadgeHandle"
                    [ngTemplateOutletContext]="{
                                                itemHandle: item?.firstMetadataValue('dc.identifier.uri'),
                                              }"
                    *ngIf="!item?.firstMetadataValue('cg.identifier.doi')">
      </ng-container>
    </div>
  </div>
</div>

<!-- See: https://badge-docs.altmetric.com/getting-started.html -->
<!-- Prefer using the Altmetric score for the DOI if it is present -->
<ng-template #altmetricBadgeDoi let-itemDoi="itemDoi">
  <div class="altmetric-embed" data-hide-no-mentions="true" data-badge-type="donut"
       [attr.data-badge-popover]="badgePopover"
       data-link-target="_blank" [attr.data-doi]=parseDoi(itemDoi)></div>
</ng-template>

<ng-template #altmetricBadgeHandle let-itemHandle="itemHandle">
  <div class="altmetric-embed" data-hide-no-mentions="true" data-badge-type="donut"
       [attr.data-badge-popover]="badgePopover"
       data-link-target="_blank" [attr.data-handle]=parseHandle(itemHandle)></div>
</ng-template>
